import React from 'react';

function QLogOutput({ queryResults }) {
  // show query results here

  return (
    <div id="q-log-output-container">
      {/* <pre>{JSON.stringify(queryResults, null, 2)}</pre> */}
    </div>
  );
}

export default QLogOutput;
